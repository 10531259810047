.cart-step-container {
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: height 0.3s ease-in-out;
}

.cart-step-empty {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.cart-step-empty-inner {
	width: 70%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 2%;
}
.cart-step-products-container {
	display: contents;
}
.cart-step-header,
.cart-step-item {
	display: grid;
	grid-template-columns: 0.2fr 0.7fr 1fr 0.6fr 1fr 0.3fr 0.5fr;
	gap: 10px;
	align-items: center;
	padding: 10px 0;
}

.cart-step-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.cart-step-items {
	margin-top: 20px;
}

.cart-step-item {
	border-bottom: 1px solid #ddd;
}

.cart-step-item-image {
	width: 50px;
	height: 50px;
	object-fit: cover;
	border-radius: 4px;
}

.cart-step-action-icon {
	cursor: pointer;
	color: #555;
}

.cart-step-total-container {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: flex-end,
}

.cart-step-total {
	font-size: 1.2em;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
