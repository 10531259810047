.qa-section {
    margin-top: 2vh;
    width: 100%;
  }
  
  .qa-item {
    margin: 10px 0;
    border-top: 1px solid #ccc;
  }
  
  .qa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .qa-header:hover {
    background-color: #eaeaea;
  }
  
  .qa-header.expanded {
    background-color: #eaeaea; 
  }
  .qa-content {
    max-height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .qa-content.expanded {
    min-height: fit-content; 
    transition: height 0.3s ease;
    padding: 10px 25px;
    max-height: 1000px; 
  }
  
  .qa-p{
    margin-left: 1vw;
  }

  