.login-form {
    padding: 20px;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.button-container {
    margin-top: 20px;
}
