.admin-panel-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f7f7f7;
}

.admin-add-form-container {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.order-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ddd;
  padding: 0vh 1.5vh;
}

.order-heading:hover {
  background-color: #e5e5e5;
}

.order-heading.expanded {
  background-color: #e0e0e0;
}

.order-title {
  font-weight: 300;
  color: #333;
}

.order-info {
  padding: 24px;
  font-size: 1em;
  color: #444; 
  line-height: 1.5;
  transition: all 0.3s ease;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.order-details{
  display: flex;
  width: 100%;
  flex-direction: column;
}
.order-detail-row {
  width: 45%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px; /* Increased spacing between rows */
  padding-bottom: 8px;
  border-bottom: 1px solid #eee; /* Light border for better separation */
}

.order-detail-label {
  font-weight: 500;
  color: #555;
}

.order-detail-value {
  width: 50%;
  text-align: left;
  font-weight: bold;
  color: #222;
}

.order-products-list {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  justify-content: space-between;
}

.order-products-single {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
}

.order-products-single-border{
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.order-products-single p {
  font-size: 1em;
}

.order-products-total-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 0;
  width: 100%;
}

.order-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9; 
}

.status-label {
  font-weight: 600;
  color: #555;
  display: flex;
}

.status-value {
  font-weight: bold;
  color: #333;
}

.status-shipped {
  color: green;
}

.status-pending {
  color: red;
}

/* Receipt Actions */
.receipt-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.receipt-input {
  padding: 8px;
  width: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.receipt-input:focus {
  outline: none;
  border-color: #4caf50;
}

.receipt-button {
  padding: 10px 20px;
  background-color: #4caf50; /* Green button for "mark as shipped" */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.receipt-button:hover {
  background-color: #45a049;
}

.delete-button {
  padding: 10px 20px;
  background-color: #f44336; /* Red button for delete */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.delete-button:hover {
  background-color: #e53935;
}

/* Divider Styling */
.MuiDivider-root {
  background-color: #ddd; /* Slightly lighter divider color */
}

/* Responsive */
@media (max-width: 768px) {
  .admin-add-form-container {
    padding: 16px; /* Reduce padding on smaller screens */
    min-width: 100%;
  }

  .order-info {
    padding: 16px; /* Adjust padding for mobile */
  }
}
