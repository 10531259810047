/* CartModal */

.cart-modal-container {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	overflow-x: hidden;
}
.animate-slide-in {
	animation: slideInReverse 0.3s ease-in;
}

@keyframes slideInReverse {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
	}
}

.cart-modal-container {
	position: fixed;
	top: 85px;
	right: 0;
	background-color: var(--primary-main);
	border: 1px solid var(--custom-opposite-lighter);
	border-right: none;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	width: 20vw;
	max-height: calc(100vh - 85px);
	direction: rtl;
}

.cart-modal-container.animate-slide-in {
	animation: slideInReverse 0.3s ease-in-out;
}

.cart-close-icon-container {
	width: 100%;
	background-color: white;
}
.cart-close-icon {
	margin-top: 10px;
	margin-right: 15px;
	font-size: 2em;
	cursor: pointer;
	color: var(--text-primary);
}

.cart-modal-content {
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
}
.cart-empty-message {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 20px;
}
.cart-item-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.cart-item-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.cart-item {
	display: grid;
	grid-template-columns: 1fr 2.5fr 1fr 1fr; 
	direction: ltr;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid var(--custom-opposite-lighter);
	width: 90%;
}

.cart-item-image {
	width: 50px;
	height: 50px;
	object-fit: cover;
}

.cart-item-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding-left: 10px;
}

.cart-item-title {
	color: var(--text-primary);
	font-size: 0.95rem;
	margin-bottom: 5px;
}

.cart-item-count {
	color: var(--text-primary);
	font-size: 0.85em;
}

.cart-item-actions {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
}

.cart-item-action-icon {
	cursor: pointer;
	color: var(--text-primary);
}

.cart-total-price {
	text-align: center;
	color: var(--text-primary);
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: flex-start;
    border-left: none;
    border-bottom: none;
}

.cart-price {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
    margin-right: 1vw;
}

.cart-procced {
    width: 95%;
    padding:1vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-procced-link:hover {
	color: grey;
	cursor: pointer;
}

@keyframes slideIn {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}
