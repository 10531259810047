.checkout-step-container {
  height: fit-content;
  width: 100%;
  transition: height 0.3s ease-in-out;
}

.checkout-step-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.checkout-step-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.checkout-step-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.form-control-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;

}

.checkout-step-error{
    color: red;
    font-size: 0.7em;
    height: 20px;
    position: absolute;
    margin-left: 1vw;
    margin-top: 5px;
}

.spinner-checkout {
  border-top: 6px solid black; /* Reduced thickness */
  border-radius: 50%; /* Ensures it's a perfect circle */
  width: 70px; /* Adjusted size */
  height: 50px; /* Adjusted size */
  animation: spin 1.5s linear infinite; /* Adjusted speed */
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* checkout-step.css */
.checkout-step-bank-account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  width:100%;
}

.checkout-step-bank-account span {
  font-size: 1em;
  font-weight: bold;
  color: #333;
  padding: 20px 0;
}

.checkout-step-bank-account div {
  font-size: 0.9em;
  color: #666;
  text-align: justify;
  padding: 10px 0;
}

.checkout-step-bank-account .divider {
  width: 100%;
}

