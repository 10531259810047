.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  min-width: 350px;
}

.footer-column {
  padding: 15px;
  font-size: 0.8em;
  display: flex;
}

.footer-column-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1vh;
}
.footer-heading {
  font-weight: 600;
  margin-bottom: 0.5vh;
}

.footer-section {
  margin-top: 1vh;
  cursor: pointer;
  display: flex;
  align-items: center;
  word-break: break-all;
  white-space: normal;
  overflow-wrap: break-word;
}

.footer-section:hover {
  color: #3b3b3b; 
  transition: all 0.3s;
}