.shoppage-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 1;
}

.shoppage-inner-container {
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: auto; 
	flex-grow: 1;
}
.hover-style-link-orange:hover {
	color: grey !important;
	text-decoration: underline;
}

.shoppage-filtered-container {
	width: 95%;
	height: fit-content;
	justify-items: center;
	align-items: center;
	justify-content: center;
	gap: 20px;
	grid-template-columns: repeat(4, 1fr);
	transition: all 0.3s ease-in-out;
}

@media (max-width: 1500px) {
	.shoppage-filtered-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 768px) {
	.shoppage-filtered-container {
		grid-template-columns: repeat(1, 1fr);
	}
}

.spinner-container{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 60vh;
	width: 100%;
}
.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: black;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
	margin: 0 auto; /* Center the spinner */
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  