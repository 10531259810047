.products-mainpage-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  display: flex;
  /* justify-content: flex-end; */
}

.product-container {
  width: 100%;
}

.products-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(15%);
  transition: transform 0.4s ease-in, opacity 0.4s ease-in;
  margin-bottom: 30px;
  width: 95%;
}

.products-slide-in {
  opacity: 1;
  transform: translateY(0%);
}

.products-allproducts-link {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: color 0.3s ease;
  margin-right: 2vh;
}

.products-allproducts-link:hover {
  color: grey;
  cursor: pointer;
}
