.admin-add-product-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
  }
  
  .admin-add-product-form {
       display: flex;
       flex-direction: column;
       flex-wrap: wrap;
       padding: 30px;
       width: 90%;
       
  }
  .admin-form-image{
    height:50px;
    width: 50px;
  }
  .admin-form-group {
    display: flex;
    justify-content: space-between;
    gap: 0px 10px;

  }
  .admin-form-images-row {
    display: flex;
    justify-content: space-between; /* Spread the items evenly */
    align-items: flex-start;
    margin-bottom: 30px;
  }
  
  .admin-form-group-images {
    flex: 1; /* Make each image upload section take up equal space */
    margin: 0 10px; /* Add margin to create space between each section */
    display: flex;
    min-height: 250px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 2px dashed #ddd; /* Dashed border to indicate image drop area */
    padding: 20px;
    border-radius: 8px; /* Rounded corners for a modern look */
    background-color: #f9f9f9; /* Light background to highlight the image area */
    transition: background-color 0.3s ease;
  }
  
  .admin-form-group-images:hover {
    background-color: #f1f1f1; /* Slight change in background on hover */
  }
  
  .admin-form-group-images label {
    font-weight: bold;
    margin-bottom: 15px; /* Spaced label from the input */
    font-size: 14px;
    color: #333;
  }
  
  .admin-form-group-images input[type="file"] {
    display: none; /* Hide the default file input */
  }
  
  .custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .custom-file-upload:hover {
    background-color: #0056b3;
  }
  
  .admin-form-image {
    width: 150px; /* Set a consistent size for the image */
    height: 150px;
    object-fit: cover; /* Crop the image to fill the container */
    margin-top: 15px; /* Add some space between image and label/input */
    border-radius: 10px; /* Round the image corners */
    border: 2px solid #ddd; /* Light border around the image */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease, border-color 0.3s ease;
  }
  
  .admin-form-image:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
    border-color: #007bff; /* Blue border on hover */
  }
  
  .submit-btn {
    width: 100%;
    background-color: lightgrey !important;
    color: black !important;
    box-shadow: none;
  }
  
  