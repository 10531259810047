.search-input-container {
	min-width: 200px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon-cursor {
	cursor: pointer;
}

.input-adornment {
	margin-right: -1px;
}

.divider {
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.language-desktop-select {
	cursor: pointer;
	display: flex;
}