.about-us-page-container {
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 1;
	min-width: 350px;
}

.about-us-image-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-image: url("../../media/images/about us-main.png");
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.about-us-image-container > span {
	font-weight: lighter;
	text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
	opacity: 0;
	transform: translateY(20%);
	transition: all 0.5s ease-in;
}

.about-us-section-container {
	opacity: 0;
	transform: translateX(20%);
	transition: all 0.5s ease-in;
	width: 95%;
	margin: 5vh 0;
	display: flex;
	align-items: center;
	width: 75%;
}

.about-us-section-container > h2{
	width: 70%;
}
.about-us-section-container-left {
	transform: translateX(-20%);
}

.about-us-image-container > span.slide-in {
	opacity: 1;
	transform: translateY(0);
}

.about-us-section-container.slide-in {
	opacity: 1;
	transform: translateX(0);
}

.about-us-section-image {
	min-width: 320px;
	height: 100%;
	background-color: aqua;
}

.about-us-section-image1 {
	background-image: url("../../media/images/aboutus-1.png");
	background-size: cover;
	background-position: center;
}

.about-us-section-image2 {
	background-image: url("../../media/images/aboutus-2.png");
	background-size: cover;
	background-position: center;
}

.about-us-section-content-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}

