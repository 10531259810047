.cart-checkout-page-container {
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 1;
	min-width: 350px;
}

.progress-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.circle-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.circle-label {
	bottom: 40px;
	font-size: 12px;
	color: black;
	text-align: center;
	position: absolute;
}

.progress-line {
	position: relative;
	height: 1px;
	background-color: #ccc;
	width: 100%;
	margin: 0 10px;
}

.progress-line .filled {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #000;
	width: 0%;
	transition: width 0.3s;
}

.circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: white;
	cursor: pointer;
	z-index: 1;
}

.circle.active {
	background-color: #000;
}

.step-content {
	height: fit-content;
	display: flex;
    justify-content: center;
	transition: height 0.3s ease-in-out;
}
