@keyframes fillAnimation {
    0% {
      clip-path: inset(100% 0 0 0); 
    }
    100% {
      clip-path: inset(0% 0 0 0); 
    }
  }
  
  .loading-path {
    fill: rgb(255, 255, 255);
    animation: fillAnimation 0.75s linear infinite; 
    will-change: clip-path; 
  }
  