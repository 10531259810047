.breadcrumb-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96.5%;
  }
  
  .breadcrumb-container.small-screen {
    margin-top: 140px;
  }
  
  .breadcrumb {
    color: black;
    cursor: pointer;
    font-weight: 100;
    font-size: 1em;
  }
  
  .breadcrumb:hover {
    color: grey;
    text-decoration: underline;
  }
  
  .breadcrumbs {
    z-index: 1;
  }
  
  .bredcrumbs-filter-icon-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .bredcrumbs-filter-icon-label {
    margin-left: 10px;
  }
  