.about-us {
  display: flex;
  justify-content: center;
  position: relative;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: brightness(0.7);
}

.about-us-content {
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.about-us-content-inner {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100%;
  justify-content: space-around;
}

.about-us-p {
  margin: auto;
}
.about-us-paragraphs {
  overflow: hidden;
}
.about-us-header,
.about-us-paragraphs,
.about-us-button {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.slide-in {
  opacity: 1;
  transform: translateX(0);
}
