.product-detailed-page-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 1;
	min-width: 350px;
}

.product-detailed-product-container {
	width: 90%;
	display: flex;
}

.product-detailed-info {
	display: flex;
	flex-direction: column;
	height: fit-content;
	min-height: 100%;
}
.product-detailed-add-to-cart {
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding: 10px;
	border-radius: 5px;
	transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.product-detailed-add-to-cart:hover {
	color: grey;
	transform: scale(1.05);
}

.image-gallery-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100%;
}

.main-image-container {
	width: 100%;
	max-width: 500px;
	min-height: 300px;
	border: 2px solid #ddd;
	border-radius: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 100%;
	height: 500px;
	position: relative;
}

.main-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.image-gallery {
	display: flex;
	gap: 10px;
	margin-top: 2vh;
	height: 30%;
}

.thumbnail-image {
	width: 80px;
	height: 80px;
	object-fit: cover;
	cursor: pointer;
	border: 2px solid transparent;
	border-radius: 5px;
	transition: border 0.3s ease;
}

.thumbnail-image.selected {
	border-color: #000000;
}

.product-detailed-section {
	display: flex;
	align-items: center;
	height: fit-content;
	min-height: 50px;
	padding-left: 1vh;
}

.product-detailed-options {
	display: flex;
	gap: 10px;
	margin: 10px 0 10px 10px;
	position: relative;
	flex-wrap: wrap; 
}

.color-circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: inline-block;
	border: 1px solid #ccc;
	margin-left: 10px;
}

.product-detailed-stock {
	margin-right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.product-detailed-sizes {
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 5px;
	border: 1px solid black;
	transition: all 0.3s ease;
}

.product-detailed-ordering{

}

  
  .modal-content {
	right:0;
	background-color: white;
	padding: 1.5vh;
	border-radius: 2px;
	text-align: center;
	position: absolute;
	z-index: 100;
	font-size: 0.825em;
	width: 170%;
	text-align: left;
  }

  .modal-content-p{
	color:rgb(0, 60, 255);
	text-decoration: underline;
	cursor: pointer;

  }
  /* .product-detailed-stock .MuiSvgIcon-root {
	transition: transform 0.3s ease;
  }
  
  .product-detailed-stock .MuiSvgIcon-root:hover {
	transform: scale(1.1);
  } */

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-overlay {
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%; 
    background-color: white;
    border-radius: 8px;
    text-align: center;
    position: relative;
    z-index: 1001;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
