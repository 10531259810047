.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider {
  display: flex;
  overflow: hidden;
  position: relative;
}

.slider-inner {
  display: flex;
  transition: transform 0.75s ease-in-out;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  transform: translateY(-50%);
}

.prev-btn,
.next-btn {
  padding: 0 4vh;
  cursor: pointer;
}

.bullet-bar {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
}

.bullet {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.61);
  cursor: pointer;
  border: 1px solid white;
  opacity: 0;
  transform: translateY(-100%);
  animation: slideInFromTop 0.3s ease-out forwards;
}

.bullet.active {
  background-color: white;
  opacity: 1;
}

.slider-logo-container {
  position: absolute;
  bottom: 2vh;
  right: 2vh;
  z-index: 1;
  animation: slideInFromRight 0.5s ease-out forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
