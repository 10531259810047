.admin-panel-orders-grid-container {
	height: fit-content;
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: white;
	overflow: auto;
	gap: 7px;
}

.grid-container {
	display: grid;
	grid-template-columns: 0.2fr 1fr 1fr 1fr 200px 1fr 1fr 1fr 1fr 0.5fr;
	grid-auto-rows: minmax(70px, auto);
	min-width: 800px;
	color: black;
    gap: 5px 10px;
    background-color: rgb(238, 238, 238);
}

.grid-container:hover {
    background-color: rgb(230, 227, 227);
}
.admin-panel-orders-grid-titles-container {
	background-color: rgb(0, 0, 0);
}
.admin-panel-orders-grid-titles-container:hover {
	background-color: rgb(0, 0, 0);
}

.admin-panel-orders-grid-titles {
	color: rgb(255, 255, 255);
	display: flex;
	align-items: center;
	font-weight: 300;
}

/* .admin-panel-orders-container {
} */

.admin-panel-orders {
	display: flex;
	align-items: center;
	font-weight: 400;
	overflow: hidden;
}

.custom-form-control {
	width: 100%;
	display: flex;
}

.custom-input-label {
	margin-bottom: 5px;
	display: block;
}

.custom-select {
	color: rgb(0, 0, 0);
	width: 100%;
	padding: 10px;
	background-color: transparent;
	text-align: center;
	border: none;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
	border-bottom: 1px solid rgb(0, 0, 0);
}

.custom-select option {
	cursor: pointer;
}
.custom-select:focus {
	outline: none;
}

.custom-select option {
	color: black;
}
