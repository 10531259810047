/* Header*/

.heading-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.header-container {
	position: fixed;
	top: 0;
	width: 100%;
	height: 85px;
	min-width: 290px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 40;
	min-width: 350px;
}

.header-logo-container {
	display: flex;
	flex: 0 0 20%;
	padding-left: 2vh;
}

.header-logo {
	min-height: 100px;
	padding: 1vh 0;
	cursor: pointer;
}

.header-links-container{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80%;
	flex: 0 0 50%;
}
.header-links-inner-container{
	display: flex;
	width: 60%;
	justify-content: space-around;
}
.header-links{
	font-weight: 300;
	cursor: pointer;
	transition: all 0.3s;
	color: rgb(156, 156, 156);
}

.header-links:hover{
	color: rgb(117, 117, 117);
}
/* MobileSearch */
.mobile-search-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 85px;
	height: 55px;
	width: 100%;
	min-width: 290px;
	border-radius: 0px 0px 2px 2px;
	z-index: 10 ;
	position: fixed;
	min-width: 350px;
}

.mobile-search {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70%;
}

.back-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 999;
}

/* SmallScreenFilter */
.small-screen-filter-container {
	margin-top: 140px;
	transition: height 0.3s;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	border-radius: 0px 0px 3px 3px;
	z-index: 5;
	position: fixed;
}
