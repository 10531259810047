.filter-bar {
  position: fixed;
  top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 51px;
  height: calc(100vh - 85px);
  min-height: 550px;
  z-index: 30;
}

.filter-divider {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.expanded-filter {
  min-width: 220px;
  width: 20vw;
  height: calc(100vh - 85px);
  position: absolute;
  box-shadow: 0.5px 0px 1px 0.5px rgba(0, 0, 0, 0.1),
    inset 0.5px 0px 1px 1px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-in-out;
  z-index: 20;
}


@keyframes slideIn {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
