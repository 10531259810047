.product-container {
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	position: relative;
}

.product-image {
	width: 100%;
	height: 300px;
	object-fit: cover;
	transition: opacity 0.3s ease-in-out;
}

.image-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	cursor: pointer;
	overflow: hidden;
}

.image-wrapper:hover .prev-button,
.image-wrapper:hover .next-button {
	display: flex;
}

.prev-button,
.next-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgb(236, 236, 236);
	z-index: 5;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	display: none;
	width: 30px;
	height: 30px;
}

.prev-button {
	left: 10px;
}

.next-button {
	right: 10px;
}

.prev-button svg,
.next-button svg {
	font-size: 18px;
}

.product-title {
	font-size: 1.2em;
	width: 80%;
	display: flex;
	justify-content: space-between;
}

.price-cart-container {
	display: flex;
	width: 80%;
	justify-content: space-evenly;
	cursor: pointer;
}

.product-price {
	font-size: 1.1em;
	width: 90%;
	display: flex;
	justify-content: flex-start;
}

.product-price-column{
	display: flex;
	flex-direction: column;
}

.strikethrough {
	text-decoration: line-through;
	margin-right: 5px;
	color: red;
	font-weight: bold;
}

.cart-icon-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
}

.product-title {
	cursor: pointer;
	font-weight: 550;
}
.tag-new {
	background-color: green;
	color: white;
	border-radius: 4px;
	font-size: 0.8rem;
	margin-left: 8px;
	font-weight: 150;
	padding: 5px;
	max-height: 15px;
	display: flex;
    align-items: center;
    justify-content: center;
}

.product-container {
	opacity: 0;
	transition: opacity 0.3s ease-in;
  }
  
  .product-container.visible {
	opacity: 1;
  }