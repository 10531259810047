.admin-panel-products-grid-container {
    height: fit-content;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: auto;
    gap: 7px;
  }
  
  .grid-container-products {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    grid-auto-rows: minmax(70px, auto);
    min-width: 800px;
    color: black;
    gap: 5px 10px;
    background-color: rgb(238, 238, 238);
  }
  
  .grid-container-products:hover {
    background-color: rgb(230, 227, 227);
  }
  
  .admin-panel-products-grid-titles-container {
    background-color: rgb(0, 0, 0);
  }
  
  .admin-panel-products-grid-titles-container:hover {
    background-color: rgb(0, 0, 0);
  }
  
  .admin-panel-products-grid-titles {
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    font-weight: 300;
  }
  
  .admin-panel-products {
    display: flex;
    align-items: center;
    font-weight: 400;
    overflow: hidden;
  }
  
  .custom-form-control {
    width: 100%;
    display: flex;
  }
  
  .modal-admin-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-admin-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 90%;
    max-width: 400px;
  }
  
  .modal-content h3 {
    margin-bottom: 20px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-around;
  }
  
  .modal-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .confirm-btn {
    background-color: #d9534f; /* Bootstrap's danger red */
    color: white;
  }
  
  .cancel-btn {
    background-color: #5bc0de; /* Bootstrap's info blue */
    color: white;
  }
  
  .modal-btn:hover {
    opacity: 0.9;
  }
  