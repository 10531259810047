.form-wrapper{
  height: 100%;
  width: 100%;
  position: absolute;
}
.filter-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .filters-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    width: 20%;
    min-height: 40px;
  }
  
  .reset-button:hover {
    background-color: var(--forth-color);
  }

  .formik-container {
    height: 100%;
    width: 100%;
  }
  .filter-expanded-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    height: 90%;
    background-color: white;
  }

  .filter-heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  
  
  .filter-search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .filter-search {
    width: 82.5%;
    min-width: 200px;
  }
  
  .text-field-input {
    width: 100%;
  }
  
  .expanded-content-map {
    width: 100%;
    display: flex;
    justify-content: center;
    height: fit-content;
    flex-wrap: wrap;
  }
  
  .filter-heading-formik {
    font-size: 0.75em;
    width: 97%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .filter-heading-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 80%;
  }
  .filter-title {
    width: 85%;
    font-weight: bold;
  }
  
  .filter-group-content{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .slider-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .slider-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 65%;
  }
  
  .sliderbar-container {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .price-input {
    width: 60px;
    text-align: center;
  }
  
  .filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  