.confirmation-step-container{
    height: fit-content;
	width: 95%;
	display: flex;
    flex-direction: column;
	justify-content: center;
    align-items: center;
	transition: height 0.3s ease-in-out;
}

.checkmark-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .checkmark {
    width: 100px;
    height: 100px;
    stroke-width: 3;
    stroke: black; 
    fill: none;
    animation: scaleUp 0.5s ease-in-out forwards;
  }
  
  .checkmark-circle {
    stroke-dasharray: 157;
    stroke-dashoffset: 157;
    stroke-width: 2;
    stroke: black;  
    animation: drawCircle 1s ease-out forwards;
  }
  
  .checkmark-check {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    stroke-width: 4;
    stroke-linecap: round;
    stroke: black;  
    animation: drawCheck 0.5s ease-out 0.5s forwards;
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(0);
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes drawCircle {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes drawCheck {
    100% {
      stroke-dashoffset: 0;
    }
  }
  