.section-container {
    margin-bottom: 30px;
    width: 95%;
}

.section-title {
    margin-bottom: 20px;
	display: flex;
    font-weight: 400;
    transition: 0.3s all,
}

.section-title:hover {
    color: grey;
    cursor: pointer;
}


.products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px;
}

@media (max-width: 1500px) {
    .products-grid {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 768px) {
    .products-grid {
        grid-template-columns: repeat(1, 1fr); 
        justify-items: center; 
    }
}

