.header-icons-container {
	display: flex;
	justify-content: space-around;
	height: 60%;
	width: 100%;
	align-items: center;
}

.small-search-open-container {
	margin-top: 12px;
	z-index: 5;
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
