/* LanguageSelect */

.language-desktop-selected {
	width: 33px;
	height: 33px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
	transition: transform 0.3s ease-in-out;
}
.language-desktop-selected-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 25px !important;
	height: 25px !important;
	z-index: 1;
}

.language-desktop-modal {
	min-height: 120px;
	min-width: 80px;
	width: 120px;
	position: absolute;
	right: 0;
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 50;
	animation: fadeInCenter 0.2s ease-in-out;
	padding: 5px 0;
}
.language-mobile-modal-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}
.language-mobile-modal {
	min-height: fit-content;
	width: 55%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	z-index: 50;
}

.language-option-single-mobile {
	display: flex;
	align-items: center;
}

.language-mobile-modal-label {
	margin-top: 3vh;
	font-weight: 300;
}
.language-mobile-modal-link-container {
	width: 100%;
	display: flex;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 8vh;
	margin: 4vh 0;
}
.language-mobile-modal-link {
	cursor: pointer;
	font-size: 1.3em;
	padding: 3vh 0;
	font-weight: 300;
}
@keyframes fadeInCenter {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.close-icon {
	top: 10px;
	right: 15px;
	font-size: 2em;
	cursor: pointer;
}

.language-option-single {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.language-option-single:hover {
	background-color: aliceblue;
}
.language-option-single-label {
	font-size: 0.85em;
	display: flex;
	align-items: center;
	margin-left: 10px;
}
.language-desktop-option {
	width: 35px;
}

.language-mobile-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}
.language-mobile-option {
	width: 25px;
}
.language-desktop-selected-option-icons {
	width: 80%;
	height: 80%;
}

