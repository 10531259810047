.App {
	text-align: center;
}

body {
	margin: 0;
	font-family: sans-serif;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	scrollbar-width: auto;
}

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-track {
	background: transparent;
	border: 1px solid grey;
}

*::-webkit-scrollbar-thumb {
	background-color: #535353;
	border-radius: 2px;
	border: 0px solid transparent;
}

*::-webkit-scrollbar-button {
	display: none;
}